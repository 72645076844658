var l4 = {
    code: 'ar-ma',
    week: {
        dow: 6,
        doy: 12, // The week that contains Jan 1st is the first week of the year.
    },
    direction: 'rtl',
    buttonText: {
        prev: 'السابق',
        next: 'التالي',
        today: 'اليوم',
        year: 'سنة',
        month: 'شهر',
        week: 'أسبوع',
        day: 'يوم',
        list: 'أجندة',
    },
    weekText: 'أسبوع',
    allDayText: 'اليوم كله',
    moreLinkText: 'أخرى',
    noEventsText: 'أي أحداث لعرض',
};

export { l4 as default };
