var l70 = {
    code: 'th',
    week: {
        dow: 1,
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
        prev: 'ก่อนหน้า',
        next: 'ถัดไป',
        prevYear: 'ปีก่อนหน้า',
        nextYear: 'ปีถัดไป',
        year: 'ปี',
        today: 'วันนี้',
        month: 'เดือน',
        week: 'สัปดาห์',
        day: 'วัน',
        list: 'กำหนดการ',
    },
    weekText: 'สัปดาห์',
    allDayText: 'ตลอดวัน',
    moreLinkText: 'เพิ่มเติม',
    noEventsText: 'ไม่มีกิจกรรมที่จะแสดง',
};

export { l70 as default };
