var l49 = {
    code: 'lv',
    week: {
        dow: 1,
        doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
    buttonText: {
        prev: 'Iepr.',
        next: 'Nāk.',
        today: 'Šodien',
        year: 'Gads',
        month: 'Mēnesis',
        week: 'Nedēļa',
        day: 'Diena',
        list: 'Dienas kārtība',
    },
    weekText: 'Ned.',
    allDayText: 'Visu dienu',
    moreLinkText(n) {
        return '+vēl ' + n;
    },
    noEventsText: 'Nav notikumu',
};

export { l49 as default };
